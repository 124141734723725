import {Component, Input, ViewChild} from '@angular/core';
import {SearchDataProvider} from '../../../bonding_shared/services/search-data-provider';
import {
  AppConfigService,
  ATableComponent,
  BackendError,
  GrowlService,
  InvoiceCriteriaDto,
  InvoicePortalSearchResultDto,
  isErrorReasons,
  isInternalError,
  LoggedUserService,
  ReportDefinitionSimpleDto,
} from '../../../bonding_shared';
import {ElementaryRight} from '../../../bonding_shared/model/dictionary-ids';
import {PortalInvoiceService, PortalRouterService} from '../../services';
import {HttpParams} from '@angular/common/http';
import {ReportOptions} from '../../../bonding_shared/model/report-ids';
import {BusinessReportPortalService} from '../../services/portal-business-report.service';

@Component({
  selector: 'client-invoice-list',
  templateUrl: './client-invoice-list.component.html',
})
export class ClientInvoiceListComponent {
  @ViewChild(ATableComponent, {static: true}) invoiceTable: ATableComponent<InvoicePortalSearchResultDto>;

  @Input() pageSize = 20;
  @Input() showContractNumber = true;

  @Input() set dataProvider(dp: SearchDataProvider<InvoiceCriteriaDto, InvoicePortalSearchResultDto>) {
    this.invoiceTable.dataProvider = dp;
    this.invoiceTable.search();
  }

  public readonly ElementaryRight = ElementaryRight;

  constructor(
    private appConfigService: AppConfigService,
    private businessReportPortalService: BusinessReportPortalService,
    public invoiceService: PortalInvoiceService,
    private growlService: GrowlService,
    public loggedUserService: LoggedUserService,
    public router: PortalRouterService
  ) {}

  isEmpty(): boolean {
    if (!this.invoiceTable) {
      return true;
    }
    return this.invoiceTable.totalCount < 1;
  }

  get kuke(): boolean {
    return this.appConfigService.kuke;
  }

  get ecg(): boolean {
    return this.appConfigService.ecg;
  }

  warningCallback(errorReasons: BackendError) {
    if (isErrorReasons(errorReasons)) {
      errorReasons.forEach((reason) => {
        this.growlService.warning(reason.message, 'Warning!');
      });
    } else if (isInternalError(errorReasons)) {
      this.growlService.error(errorReasons.message, 'Error!');
    } else {
      const parsedError = JSON.parse(errorReasons);
      this.growlService.error(parsedError.message, 'Error!');
    }
  }

  protected onReportDefinitionSelected(reportDefinition: ReportDefinitionSimpleDto, id: number) {
    let params = new HttpParams();
    params = params.append('invoiceId', id + '');
    params = params.append(ReportOptions.ERROR_ON_EMPTY_TAB, true + '');
    this.invoiceTable.errorMessage = undefined;
    this.businessReportPortalService.generate(
      reportDefinition.id,
      reportDefinition.name + '.xlsx',
      params,
      (errorMsg) => {
        this.warningCallback(errorMsg);
      }
    );
  }
}
