<search-view objectName="Invoice" customTitle="clientInvoice.search.title" [dataProvider]="dataProvider" [atable]="invoiceListComponent.invoiceTable" [searchModeSwitchable]="false" [newButton]="false">
  <div class="additional-buttons">
    <button class="bon-btn-info" (click)="applyFilter(PredefinedFilter.CLEARED)" type="button" translate="clientInvoice.search.predefinedFilter.settled"></button>
    <button class="bon-btn-info" (click)="applyFilter(PredefinedFilter.UNCLEARED)" type="button" translate="clientInvoice.search.predefinedFilter.unsettled"></button>
  </div>
  <div class="search-criteria">
    <div *ngIf="businessObjectTypeId"  class="bon-card-group bon-label-150">
      <div class="bon-card-inner">
        <form-row  labelKey="clientInvoice.search.criteria.policyYear">
          <item-combo class="bon-input-size" [(ngModel)]="dataProvider.searchCriteria.criteria.contractLink.policyContract"
                      [items]="policyContracts" label="policyYearFormatted">
          </item-combo>
        </form-row>
      </div>
      <div class="bon-card-inner">
      </div>
    </div>
    <div *ngIf="!businessObjectTypeId" class="bon-card-group bon-label-150">
      <div class="bon-card-inner">
        <input-row labelKey="clientInvoice.search.criteria.number" [(ngModel)]="dataProvider.searchCriteria.criteria.number" name="number"></input-row>
        <input-row labelKey="clientInvoice.search.criteria.contractNumber" [(ngModel)]="dataProvider.searchCriteria.criteria.contractNumber" name="contractNumber"></input-row>
        <div class="bon-row">
          <ss-multiselect-dropdown labelKey="clientInvoice.search.criteria.status" [(ngModel)]="dataProvider.searchCriteria.criteria.statuses" name="statuses"
                                   optionsDictName="InvoiceStatus" [optionIds]="availableStatusIds" [settings]="statusMultiselectSettings"></ss-multiselect-dropdown>
        </div>
        <input-row labelKey="clientInvoice.search.criteria.titles" [(ngModel)]="dataProvider.searchCriteria.criteria.titles" name="titles"></input-row>
      </div>
      <div class="bon-card-inner">
        <date-range-row labelKey="clientInvoice.search.criteria.issueDateRange" [value]="dataProvider.searchCriteria.criteria.issueDateRange"></date-range-row>
        <date-range-row labelKey="clientInvoice.search.criteria.dueDateRange" [value]="dataProvider.searchCriteria.criteria.dueDateRange"></date-range-row>
      </div>
    </div>
  </div>
  <div class="search-results">
    <client-invoice-list [dataProvider]="dataProvider"></client-invoice-list>
  </div>
</search-view>
