import {Component, Input, ViewChild} from '@angular/core';
import {RouterService} from '../../../../bonding_shared/services/router-service';
import {ATableComponent} from '../../../../bonding_shared/components/aku-table/a-table.component';
import {SearchDataProvider} from '../../../../bonding_shared/services/search-data-provider';
import {ContractBondCriteriaDto, ContractBondExtendedDto} from '../../../../bonding_shared/model/dtos';
import {Feature} from '../../../../bonding_shared/model/dictionary-ids';
import {FeatureService, LoggedUserService} from '../../../../bonding_shared';

@Component({
  selector: 'extended-contract-bond-list',
  templateUrl: './extended-contract-bond-list.component.pug',
})
export class ExtendedContractBondListComponent {
  @ViewChild(ATableComponent, {static: true}) contractBondTable: ATableComponent<ContractBondExtendedDto>;

  @Input() contractVersionId: number = null;
  @Input() contractId: number = null;

  @Input() hideNewBond = false;

  @Input() set dataProvider(dp: SearchDataProvider<ContractBondCriteriaDto, ContractBondExtendedDto>) {
    if (!dp) {
      return;
    }
    this.contractBondTable.dataProvider = dp;
    this.contractBondTable.search();
  }

  constructor(
    public router: RouterService,
    private featureService: FeatureService,
    private loggedUserService: LoggedUserService
  ) {}

  onChange(item: string) {
    // this.contractBondTable.search();
  }

  toBondDetailsNew(contractVersionId: number, bondTypeId: number) {
    if (this.loggedUserService.portal) {
      if (this.featureService.get(Feature.INTRANET_BOND_DETAILS_IN_PORTAL)) {
        this.router.toBondDetailsNewWithBondType(0, this.contractId, bondTypeId);
      } else {
        this.router.toClientBondDetailsNewWithBondType(contractVersionId, bondTypeId);
      }
    } else {
      this.router.toBondDetailsNew(0, this.contractId);
    }
  }
}
