import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {
  BackendError,
  BondDto,
  BondVersionCriteriaDto,
  BondVersionSimpleDto,
  ContractBondCriteriaDto,
  ContractBondExtendedDto,
  ContractCompanyDto,
  ContractTechnicalDetailsDto,
  ContractVersionCriteriaDto,
  ContractVersionDto,
  ContractVersionSimpleDto,
  DictionaryBaseDto,
  InvoiceCriteriaDto,
  InvoiceDto,
  SearchCriteria,
  StatisticsDto,
} from '../../bonding_shared/model';
import {PortalRouterService} from '../services/portal-router.service';
import {
  AppConfigService,
  BondVersionService,
  ContractService,
  ContractVersionService,
  FormatService,
  InvoiceService,
  LoggedUserService,
  SearchDataProvider,
} from '../../bonding_shared/services';
import {ClientBondGuiService, ContractBondPortalService} from '../clientbond/services';
import {ContractVersionListComponent} from '../../bonding/bonding-contract/contract/components/shared/contract-version-list.component';
import {
  BondingContractDocumentType,
  BusinessObjectType,
  ContractCompanyRole,
  ContractStatus,
  ContractType,
  ElementaryRight,
  InvoiceCategory,
} from '../../bonding_shared/model/dictionary-ids';
import {BusinessUtils} from '../../bonding_shared/utils/business-utils';
import {BondingContractViewParams} from '../../bonding/bonding-contract/contract';
import {InvoiceListComponent} from '../../bonding/invoice/components/shared/invoice-list.component';

@Component({
  selector: 'common-contract-preview',
  templateUrl: './common-contract-preview.component.pug',
})
export class CommonContractPreviewComponent implements OnInit {
  @ViewChild(ContractVersionListComponent, {static: true})
  contractVersionListComponent: ContractVersionListComponent;

  @Input() versionListHidden = true;
  contractVersion: ContractVersionDto;
  bonds: BondVersionSimpleDto[];
  statistics: StatisticsDto;
  serverErrors: BackendError;
  bondDataProvider: SearchDataProvider<BondVersionCriteriaDto, BondVersionSimpleDto>;
  bondTypeDataProvider: SearchDataProvider<ContractBondCriteriaDto, ContractBondExtendedDto>;
  validFromStr: string;
  validToStr: string;
  isClient: boolean;
  coinsureds: ContractCompanyDto[];
  contractVersionDP: SearchDataProvider<ContractVersionCriteriaDto, ContractVersionSimpleDto>;
  technicalDetails: ContractTechnicalDetailsDto;

  @ViewChild(InvoiceListComponent, {static: true}) invoiceList: InvoiceListComponent;
  invoiceDataProvider: SearchDataProvider<InvoiceCriteriaDto, InvoiceDto>;

  readonly ContractType = ContractType;
  readonly ContractStatus = ContractStatus;
  readonly BusinessObjectType = BusinessObjectType;
  readonly BondingContractDocumentType = BondingContractDocumentType;

  constructor(
    private contractService: ContractVersionService,
    private bondService: BondVersionService,
    private contractBondService: ContractBondPortalService,
    private route: ActivatedRoute,
    public router: PortalRouterService,
    private apiService: AppConfigService,
    public guiService: ClientBondGuiService,
    private _loggedUserService: LoggedUserService,
    private formatService: FormatService,
    private invoiceService: InvoiceService,
    private conService: ContractService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    const contractVersionId = +params['contractVersionId'];
    this.isClient = this._loggedUserService.hasRight(ElementaryRight.CLIENT_BONDS);
    console.log('onInit: contractVersionId = ' + contractVersionId);
    this.contractService.getContractVersion(contractVersionId).subscribe({
      next: (contract) => this.afterContractReceived(contract),
      error: (error) => (this.serverErrors = error),
    });
    this.loadStatistics(contractVersionId);
  }

  afterContractReceived(contract: ContractVersionDto) {
    this.setContractVersion(contract);
    console.log('contract version loaded:: versionId = ' + this.contractVersion.id);
    this.coinsureds = this.contractVersion.contractCompanies.filter((c) => c.role.id === ContractCompanyRole.CLIENT);
    if (this.isClient) {
      this.createBondDataProvider(contract.contract.id);
    }

    this.createBondTypeDataProvider(this.contractVersion.id);
    if (this.contractVersion.validFrom !== undefined) {
      this.validFromStr = this.formatService.formatDate(this.contractVersion.validFrom);
    } else {
      this.validFromStr = '';
    }

    if (this.contractVersion.validTo !== undefined) {
      this.validToStr = this.formatService.formatDate(this.contractVersion.validTo);
    } else {
      this.validToStr = '';
    }

    this.createInvoiceDataProvider(contract);

    this.loadTechnicalDetails(contract);
  }

  get contractNumber(): string {
    return BusinessUtils.contractNumber(this.contractVersion);
  }

  setContractVersion(c: ContractVersionDto) {
    this.contractVersion = c;
    this.setContractVersionDataProvider(c.contract.id);
  }

  loadStatistics(id: number) {
    return this.contractService.getContractStatistics(id).subscribe((s) => (this.statistics = s));
  }

  loadTechnicalDetails(contract: ContractVersionDto) {
    this.technicalDetails = null;
    if (!this.kuke || !contract?.contract?.id) {
      return;
    }
    this.conService.getTechnicalDetails(contract.contract.id).subscribe((data) => {
      this.technicalDetails = data;
    });
  }

  createBondDataProvider(contractId: number) {
    this.bondDataProvider = new SearchDataProvider<BondVersionCriteriaDto, BondVersionSimpleDto>(this.bondService);
    this.bondDataProvider.searchCriteria = <SearchCriteria<BondVersionCriteriaDto>>{};
    this.bondDataProvider.searchCriteria.criteria = <BondVersionCriteriaDto>{};
    this.bondDataProvider.searchCriteria.criteria.bond = <BondDto>{};
    this.bondDataProvider.searchCriteria.criteria.contractId = contractId;
  }

  createBondTypeDataProvider(contractVersionId: number) {
    this.bondTypeDataProvider = new SearchDataProvider<ContractBondCriteriaDto, ContractBondExtendedDto>(
      this.contractBondService
    );
    this.bondTypeDataProvider.searchCriteria = <SearchCriteria<ContractBondCriteriaDto>>{};
    this.bondTypeDataProvider.searchCriteria.criteria = <ContractBondCriteriaDto>{};
    this.bondTypeDataProvider.searchCriteria.criteria.contractVersionId = contractVersionId;
  }

  onSelectContractVersion(contractVersion: ContractVersionDto) {
    console.log('onSelectContractVersion :: validFrom: ', typeof contractVersion.validFrom, contractVersion.validFrom);
    this.contractService.getContractVersion(contractVersion.id).subscribe((cv) => {
      this.afterContractReceived(cv);
      if (this.isClient) {
        this.router.toClientContractPreview(this.contractVersion.id);
      } else {
        this.router.toBrokerPortfolioContractVersionPreview(this.contractVersion.id);
      }
    });
  }

  updateButtons() {}

  setContractVersionDataProvider(contractId: number) {
    this.contractVersionDP = BusinessUtils.createContractVersionDataProvider(this.contractService);
    this.contractVersionDP.searchCriteria.criteria.contract.id = contractId;
  }

  newContractVersion() {
    this.router.toContractDetails(null, <BondingContractViewParams>{
      contractId: this.contractVersion.contract.id,
      newVersion: true,
    });
  }

  get kuke() {
    return this.apiService.kuke;
  }

  createInvoiceDataProvider(contract: ContractVersionDto) {
    this.invoiceDataProvider = new SearchDataProvider<InvoiceCriteriaDto, InvoiceDto>(this.invoiceService);
    this.invoiceDataProvider.searchCriteria = <SearchCriteria<InvoiceCriteriaDto>>{};
    this.invoiceDataProvider.searchCriteria.criteria = <InvoiceCriteriaDto>{};
    this.invoiceDataProvider.searchCriteria.criteria.contractNumber = contract.contract.number;
    this.invoiceDataProvider.searchCriteria.criteria.category = <DictionaryBaseDto>{id: InvoiceCategory.INVOICE};
    this.invoiceDataProvider.textSearch = false;
  }

  isRequest(): boolean {
    if (
      this.contractVersion &&
      this.contractVersion.status &&
      this.contractVersion.status.id !== ContractStatus.CONTRACT_PROSPECT &&
      this.contractVersion.status.id !== ContractStatus.CONTRACT_REQUEST_ACCEPTED &&
      this.contractVersion.status.id !== ContractStatus.CONTRACT_REQUEST &&
      this.contractVersion.status.id !== ContractStatus.CONTRACT_REQUEST_REJECTED &&
      this.contractVersion.status.id !== ContractStatus.CONTRACT_REQUEST_WITHOUT_RESULT
    ) {
      return false;
    }
    return true;
  }
}
