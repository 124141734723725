import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {RouterService} from '../../../../bonding_shared/services/router-service';
import {SalesRepRelationVersionService} from '../../../../bonding_shared/services';
import {SalesRepRelationListComponent} from './sales-rep-relation-list.component';
import {BusinessObjectType} from '../../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'portal-sales-rep-relation-contract-list',
  templateUrl: './portal-sales-rep-relation-list.component.pug',
})
export class PortalSalesRepRelationContractListComponent extends SalesRepRelationListComponent {
  constructor(
    protected salesRepRelationVersionService: SalesRepRelationVersionService,
    public router: RouterService,
    protected translateService: TranslateService
  ) {
    super(salesRepRelationVersionService, router, translateService);
    this.relatedToTypeId = BusinessObjectType.CONTRACT;
    this.type = 'contract';
  }

  get isHidden(): boolean {
    return !this.resultList?.items || this.resultList.items.length === 0 || this.resultList.items.length === 1;
  }

  get oneUser() {
    if (this.resultList?.items?.length === 1) {
      return this.resultList.items[0].salesRepRelation.salesRep.user;
    }
    return null;
  }
}
