import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {
  AbstractService,
  AppConfigService,
  GrowlService,
  PolicyContractService,
  PolicyContractVersionService,
  PolicyLimitListService,
  RouterService,
} from '../../bonding_shared/services';
import {
  DeclarationBuyerCriteriaDto,
  DeclarationWithoutEntriesDto,
  PolicyContractDto,
  PolicyContractSimpleIdDataDto,
  LimitListVersionInDeclarationDto,
  ReportingPeriodDto,
  CompanyInDeclarationDto,
} from '../../bonding_shared/model';
import {PolicyContractType} from '../../bonding_shared/model/dictionary-ids';
import {DeclarationService} from '../../bonding_shared/services/declaration.service';
import {DeclarationCriteria, DeclarationParams} from '../../bonding/declaration/shared/declaration-details-view';

@Component({
  selector: 'declaration-history-criteria',
  templateUrl: '../../bonding/declaration/shared/components/declaration-criteria.component.pug',
})
export class DeclarationHistoryCriteriaComponent {
  singleFinalConfirmedDeclarationsForPolicy: DeclarationWithoutEntriesDto[] = [];
  allReportingPeriods: ReportingPeriodDto[];
  allReportingPeriodsMap: Map<number, ReportingPeriodDto[]>;
  amendmentBlocked_flag: boolean;

  @Input() withBuyerFilter = false;

  constructor(
    private route: ActivatedRoute,
    private routerService: RouterService,
    private appService: AppConfigService,
    private policyContractVersionService: PolicyContractVersionService,
    private policyContractService: PolicyContractService,
    protected translateService: TranslateService,
    protected growlService: GrowlService,
    private router: RouterService,
    private policyLimitListService: PolicyLimitListService,
    public declarationService: DeclarationService
  ) {}

  @Input() policyService: AbstractService;

  @Input() dc: DeclarationCriteria;

  intranet = false;
  outstandingsSP: boolean;
  // (used to distinguish whether to show or not reportingTo date) AND (to filter on portal amendment view declared on list)
  amendmentDeclaration: boolean;
  pureAmendmentView: boolean;
  declarationIsEffective = true;
  @Input() criteriaDisabled: boolean;

  @Output() policyChanged = new EventEmitter();
  @Output() criteriaTouched = new EventEmitter();
  @Output() criteriaChanged = new EventEmitter<boolean>();
  @Output() filteringChanged = new EventEmitter();

  showSelectPolicyMessage = false;
  showSelectRpMessage = false;
  showK7message = false;
  showNoConfirmedDeclarationsForSelectedLimitListMessage = false;

  searchWithFilter = false;

  selectedPolicyIsOutstanding(): boolean {
    return (
      this.dc.selectedPolicy &&
      this.dc.selectedPolicy.productType &&
      this.dc.selectedPolicy.productType.id === PolicyContractType.KUKE_KOM_SP
    );
  }

  public portal() {
    return true;
  }

  public get ecgPortal() {
    return this.appService.ecg && this.portal();
  }

  public goToPolicy() {
    if (this.portal()) {
      return this.routerService.toClientPolicyPreviewKuke(this.dc.lastPolicyContractVersionId);
    } else {
      return this.routerService.toPolicyContractPreview(this.dc.lastPolicyContractVersionId);
    }
  }

  public initHistoryByPortal(outstandingsMode: boolean) {
    this.pureAmendmentView = true;
    if (outstandingsMode) {
      this.declarationService.findClientReportingPeriodsForOutstandingsHistory().subscribe(
        (rps) => this.initByRpsList(rps),
        () => this.growlService.error('Error while getting reporting periods list for outstandings history!')
      );
    } else {
      this.declarationService.findClientReportingPeriodsForTurnoversHistory().subscribe(
        (rps) => this.initByRpsList(rps),
        () => this.growlService.error('Error while getting reporting periods list for turnover history!')
      );
    }
  }

  private initByRpsList(rps: ReportingPeriodDto[]) {
    this.allReportingPeriods = rps;
    this.allReportingPeriodsMap = new Map<number, ReportingPeriodDto[]>(); // <policy.id, [rp, rp, ...]>
    const policiesReduced: PolicyContractSimpleIdDataDto[] = []; // distinct policy

    rps.forEach((rp) => {
      if (!this.allReportingPeriodsMap.has(rp.policyContractData.id)) {
        this.allReportingPeriodsMap.set(rp.policyContractData.id, []);
        policiesReduced.push(rp.policyContractData);
      }
      this.allReportingPeriodsMap.get(rp.policyContractData.id).push(rp);
    });
    this.setPoliciesList(policiesReduced);
  }

  public clearSelections() {
    this.dc.selectedDecOn = undefined;
    this.dc.selectedLlv = undefined;
    this.dc.selectedRp = undefined;
  }

  onSelectedPolicyByPolicy(policy: PolicyContractSimpleIdDataDto) {
    this.onSelectedPolicy(policy);
  }

  private onSelectedPolicy(policy: PolicyContractSimpleIdDataDto) {
    this.criteriaTouched.emit();
    this.showSelectPolicyMessage = policy === undefined;

    this.clearSelections();
    this.clearRpLLvDecOnLists();
    this.dc.selectedPolicy = policy;
    this.outstandingsSP = this.selectedPolicyIsOutstanding();
    this.policyChanged.emit();

    if (policy) {
      this.policyService.getById<PolicyContractDto>(policy.id).subscribe((pc) => {
        // set last policy contract version (linking purpose)
        const lastPcv = pc.versions[pc.versions.length - 1];
        this.dc.lastPolicyContractVersionId = lastPcv.id;
        this.buildPolicyRpMap(policy.id);
      });
    } else {
      this.criteriaChanged.emit(true);
    }
  }

  private buildPolicyRpMap(policyId: number) {
    this.declarationService.findFinalConfirmedDeclarationsForPolicy(policyId).subscribe((declarations) => {
      this.singleFinalConfirmedDeclarationsForPolicy = declarations;
      this.setRpList(this.getProperReportingPeriods(policyId));
    });
  }

  private getProperReportingPeriods(policyId: number) {
    let sortedRpsForPolicy = [];
    const rpsOnlyForPolicy = this.allReportingPeriodsMap.get(policyId);
    if (rpsOnlyForPolicy) {
      sortedRpsForPolicy = rpsOnlyForPolicy.sort((a, b) => (a.periodTo < b.periodTo ? -1 : 1));
    }
    return sortedRpsForPolicy;
  }

  onSelectedReportingPeriodByPeriod(rp: ReportingPeriodDto) {
    this.onSelectedReportingPeriod(rp);
  }

  private onSelectedReportingPeriod(rp: ReportingPeriodDto) {
    this.criteriaTouched.emit();
    this.dc.selectedRp = rp;
    this.showSelectRpMessage = rp === undefined;
    this.reloadLlVAndDecOnListsAndSetDefaultOrParamsSelections(undefined);
  }

  onSelectedLimitListVersion(llv: LimitListVersionInDeclarationDto) {
    this.criteriaTouched.emit();
    this.dc.selectedLlv = llv;
    this.clearDecOnList();

    if (this.dc.selectedLlv) {
      this.declarationService.findAllCompaniesToBeDeclaredOn(this.dc.selectedLlv.id).subscribe((companies) => {
        this.dc.selectedDecOn = undefined;

        const confirmedDeclarationsForDeclaredOdIds = this.singleFinalConfirmedDeclarationsForPolicy
          .filter(
            (acd) =>
              acd.reportingPeriod.id === this.dc.selectedRp.id && acd.limitListVersion.id === this.dc.selectedLlv.id
          )
          .map((rpcd) => rpcd.declaredOn.id);

        this.setDecOnList(companies.filter((c) => confirmedDeclarationsForDeclaredOdIds.includes(c.id)));

        if (this.dc.decOnList.length === 1) {
          this.dc.selectedDecOn = this.dc.decOnList[0];
        }
        this.criteriaChanged.emit(true);
      });
    } else {
      // undefined llv param should not happen!
      this.setDecOnList([]);
      this.dc.selectedDecOn = undefined;
      this.criteriaChanged.emit(true);
    }
  }

  onSelectedDeclaredOn(decOn: CompanyInDeclarationDto) {
    this.criteriaTouched.emit();
    this.dc.selectedDecOn = decOn;
    this.criteriaChanged.emit(true);
  }

  private reloadLlVAndDecOnListsAndSetDefaultOrParamsSelections(dcParams: DeclarationParams) {
    this.reloadLlVAndDecOnListsAndSetParamsSelections(dcParams, true);
  }

  public reloadLlVAndDecOnListsAndSetParamsSelections(dcParams: DeclarationParams, reloadDeclaration = false) {
    this.clearLLvDecOnLists();
    this.dc.selectedLlv = undefined;
    if (dcParams && this.dc.rpList) {
      this.dc.selectedRp = this.dc.rpList.find((rp) => rp.id === dcParams.paramRpId);
      this.showSelectRpMessage = this.dc.selectedRp === undefined;
    }

    if (this.dc.selectedPolicy && this.dc.selectedRp) {
      this.policyLimitListService
        .listsForDeclarationsHistory(this.dc.selectedPolicy.id, this.dc.selectedRp.periodTo)
        .subscribe(
          (sr) => {
            // set policy limit list version lists
            const confirmedLLvIds = this.singleFinalConfirmedDeclarationsForPolicy.map(
              (dec) => dec.limitListVersion.id
            );
            const allLLV = sr.map((r) => <LimitListVersionInDeclarationDto>r);
            this.setLlvList(allLLV.filter((llv) => confirmedLLvIds.includes(llv.id)));
            // and main limit list version or parameter as selected
            // and main or first limit list version or from parameter as selected
            if (dcParams && dcParams.paramLlvId) {
              this.dc.selectedLlv = this.dc.llvList.find((f) => f.id === dcParams.paramLlvId);
            } else if (this.dc.llvList.length > 0) {
              this.dc.selectedLlv = this.dc.llvList.find((f) => f.mainLimitList);
              if (!this.dc.selectedLlv) {
                this.dc.selectedLlv = this.dc.llvList[0];
              }
            }
            this.reloadDecOnList(dcParams, reloadDeclaration);
          },
          () => this.growlService.error('Error while getting limit list')
        );
    } else {
      this.criteriaChanged.emit(reloadDeclaration);
    }
  }

  private reloadDecOnList(dcParams: DeclarationParams, reloadDeclaration = false) {
    this.clearDecOnList();
    this.dc.selectedDecOn = undefined;
    if (this.dc.selectedLlv) {
      this.declarationService.findAllCompaniesToBeDeclaredOn(this.dc.selectedLlv.id).subscribe((companies) => {
        this.dc.selectedDecOn = undefined;

        const confirmedDeclarationsForDeclaredOdIds = this.singleFinalConfirmedDeclarationsForPolicy
          .filter(
            (acd) =>
              acd.reportingPeriod.id === this.dc.selectedRp.id && acd.limitListVersion.id === this.dc.selectedLlv.id
          )
          .map((rpcd) => rpcd.declaredOn.id);
        this.setDecOnList(companies.filter((c) => confirmedDeclarationsForDeclaredOdIds.includes(c.id)));

        if (this.dc.decOnList.length > 1) {
          if (dcParams && dcParams.paramDecOnId) {
            this.dc.selectedDecOn = this.dc.decOnList.find((decOn) => decOn.id === dcParams.paramDecOnId);
          } else {
            this.dc.selectedDecOn = undefined;
          }
        } else {
          if (this.dc.decOnList.length === 1) {
            this.dc.selectedDecOn = this.dc.decOnList[0];
          } else {
            this.dc.selectedDecOn = undefined;
          }
        }
        this.criteriaChanged.emit(reloadDeclaration);
      });
    } else {
      this.dc.selectedDecOn = undefined;
      this.criteriaChanged.emit(reloadDeclaration);
    }
  }

  // ############## SETTERS ##############
  public setPoliciesList(policies: PolicyContractSimpleIdDataDto[]) {
    if (policies) {
      this.showSelectPolicyMessage = policies.length > 1;
      if (policies.length === 1) {
        this.dc.policiesList = policies;
        this.onSelectedPolicyByPolicy(policies[0]);
      } else {
        this.dc.policiesList = policies.sort((a, b) => (a.validTo < b.validTo ? 1 : -1));
        this.criteriaChanged.emit(true);
      }
    } else {
      this.dc.policiesList = [];
    }
  }

  private setRpList(rps: ReportingPeriodDto[]) {
    if (rps) {
      this.showSelectRpMessage = rps.length > 1;
      if (rps.length === 1) {
        this.dc.rpList = rps;
        this.onSelectedReportingPeriodByPeriod(rps[0]);
      } else {
        this.dc.rpList = rps.sort((a, b) => (a.periodTo < b.periodTo ? 1 : -1));
        this.criteriaChanged.emit(true);
      }
    } else {
      this.dc.rpList = undefined;
      this.criteriaChanged.emit(true);
    }
  }

  private setLlvList(llvs: LimitListVersionInDeclarationDto[]) {
    this.dc.llvList = llvs;
  }

  private setDecOnList(decOnList: CompanyInDeclarationDto[]) {
    this.dc.decOnList = decOnList;
  }

  private clearRpLLvDecOnLists() {
    this.dc.rpList = undefined;
    this.clearLLvDecOnLists();
  }

  private clearLLvDecOnLists() {
    this.dc.llvList = undefined;
    this.clearDecOnList();
  }

  private clearDecOnList() {
    this.dc.decOnList = undefined;
  }

  public switchSearchMode() {
    this.searchWithFilter = !this.searchWithFilter;
    if (!this.searchWithFilter) {
      this.dc.buyerCriteria = <DeclarationBuyerCriteriaDto>{};
      this.filteringChanged.emit();
    }
  }

  filterByBuyerCriteria() {
    this.filteringChanged.emit();
  }
}
