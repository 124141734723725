import {ClientBondDetailsComponent} from './clientbond-details.component';
import {ClientBondVersionSearchComponent} from './clientbond-version-search.component';
import {ClientBondGuard} from './clientbond-guard';

export const ClientBondRoutes = [
  {
    path: 'clientbond-details/:id',
    canActivate: [ClientBondGuard],
    component: ClientBondDetailsComponent,
  },
  {
    path: 'clientbond-details/:id/:contractVersionId',
    canActivate: [ClientBondGuard],
    component: ClientBondDetailsComponent,
  },
  {
    path: 'clientbond-details/:id/:contractVersionId/:bondTypeId',
    canActivate: [ClientBondGuard],
    component: ClientBondDetailsComponent,
  },
  {
    path: 'clientbond-details/:id/:contractVersionId/:bondTypeId/:replacedBondVersionId',
    canActivate: [ClientBondGuard],
    component: ClientBondDetailsComponent,
  },
  {
    path: 'clientbond-version-search/:contractVersionId',
    canActivate: [ClientBondGuard],
    component: ClientBondVersionSearchComponent,
  },
  {
    path: 'clientbond-version-search',
    canActivate: [ClientBondGuard],
    component: ClientBondVersionSearchComponent,
  },
];
