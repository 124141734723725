import {ClientInvoiceSearchComponent} from './client-invoice-search.component';
import {ClientInvoicePreviewComponent} from './client-invoice-preview.component';

export const ClientInvoiceRoutes = [
  {
    path: 'client-invoice-search',
    component: ClientInvoiceSearchComponent,
  },
  {
    path: 'client-invoice-search/:businessObjectTypeId/:businessObjectId',
    component: ClientInvoiceSearchComponent,
  },
  {
    path: 'client-invoice-search/:businessObjectTypeId/:businessObjectId/:businessObjectNumber',
    component: ClientInvoiceSearchComponent,
  },
  {
    path: 'client-invoice-preview/:category/:id',
    component: ClientInvoicePreviewComponent,
  },
];
